<template>
  <v-row>
    <v-col cols="12" md="6" order-md="2"><payout-stats /></v-col>
    <v-col cols="12" md="6" order-md="1"> <payouts-table /></v-col>
  </v-row>
</template>

<script>
import PayoutsTable from "./PayoutsTable.vue";
import PayoutStats from "./PayoutStats.vue";
export default {
  components: { PayoutStats, PayoutsTable },
};
</script>

<style></style>
