/* eslint-disable no-unused-vars */

import axios from "axios";

const state = {
  partner: {},
  disbursmentData: null,
  stats: null,
  disbursementIntervals: null,
  disbursementMethods: null,
  partnerError: null,
};

const getters = {
  currentPartner: (state) => state.partner,
  getDisbursmentData: (state) => state.disbursmentData,
  getStats: (state) => state.stats,
  getDisbursementIntervals: (state) => state.disbursementIntervals,
  getDisbursementMethods: (state) => state.disbursementMethods,
  getPartnerError: (state) => state.partnerResponseStatus,
};

const actions = {
  async fetchPartner({ commit }, partnerId) {
    const response = await axios.get(`/partners/${partnerId}?withStations`);
    commit("setPartner", response.data);
  },
  async fetchDisbursmentData(
    { commit },
    { id, page, perPage, orderBy, direction }
  ) {
    const response = await axios.get(
      `/partners/${id}/disbursements/?per_page=${perPage}&page=${page}${
        orderBy.length ? "&order_by=" + orderBy : ""
      }&order_direction=${direction}&withDisbursementMethod&withCurrency`
    );
    commit("setDisbursmentData", response.data);
  },
  async fetchStats({ commit }, id) {
    const response = await axios.get(`/partners/${id}/disbursements/stats`);
    commit("setStats", response.data);
  },
  async fetchDisbursementIntervals({ commit }) {
    const response = await axios.get("disbursementintervals");
    commit("setDisbursementIntervals", response.data);
  },
  async fetchDisbursementMethods({ commit }) {
    const response = await axios.get("disbursementmethods");

    commit("setDisbursementMethods", response.data);
  },
  async updatePartner({ commit }, { partner_id, body }) {
    let response = await axios.put(`/partners/${partner_id}`, body);
    commit("setPartner", response.data);
  },
};

const mutations = {
  setPartner(state, payload) {
    state.partner = payload;
  },
  setDisbursmentData(state, payload) {
    state.disbursmentData = payload;
  },
  setStats(state, payload) {
    state.stats = payload;
  },
  setDisbursementIntervals(state, payload) {
    state.disbursementIntervals = payload;
  },
  setDisbursementMethods(state, payload) {
    state.disbursementMethods = payload;
  },
  setPartnerError(state, payload) {
    state.partnerError = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
