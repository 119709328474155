<template>
  <v-tabs color="primary">
    <v-tab class="tab-font-size">Status</v-tab>
    <v-tab class="tab-font-size">Ports</v-tab>
    <v-tab class="tab-font-size">Settings</v-tab>
    <v-tab class="tab-font-size">History</v-tab>
    <v-tab-item>
      <v-skeleton-loader
        v-if="loading"
        type="image"
        width="100%"
        height="500px"
        :loading="true"
      ></v-skeleton-loader>
      <station-alarms v-else></station-alarms> </v-tab-item
    ><v-tab-item> <stations-table></stations-table></v-tab-item>
    <v-tab-item class="mx-3">
      <station-settings></station-settings>
    </v-tab-item>
    <v-tab-item><station-history></station-history> </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StationAlarms from "./StationAlarms.vue";
import StationHistory from "./StationHistory.vue";
import StationSettings from "./StationSettings.vue";
import StationsTable from "./StationsTable.vue";
export default {
  components: { StationsTable, StationSettings, StationHistory, StationAlarms },
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["getCurrentStation"]),
  },

  methods: {
    ...mapActions([
      "fetchCurrentStationByHumanSerial",
      "initStationEventsChannel",
      "subscribeToStationUpdates",
      "subscribeToStationCharges",
      "leaveStationEventsChannel",
    ]),
  },
  async mounted() {
    this.loading = true;
    await this.fetchCurrentStationByHumanSerial(this.$route.params.humanSerial),
      this.initStationEventsChannel(this.getCurrentStation.id);
    this.subscribeToStationUpdates();
    this.subscribeToStationCharges();
    this.loading = false;
  },
  beforeDestroy() {
    this.leaveStationEventsChannel(this.getCurrentStation.id);
  },
};
</script>

<style>
.tab-font-size {
  font-size: 1rem !important;
}
</style>
