<template>
  <v-container>
    <v-card max-width="500" class="mx-auto my-12" elevation="0">
      <v-img
        src="../../assets/brokenRobot.png"
        class="my-3"
        contain
        height="200"
      />
      <v-card-title>
        Ooops! You need to be a parter to use this app!
      </v-card-title>
      <v-card-actions>
        <v-btn class="mx-auto" color="primary" to="/login">To login</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
