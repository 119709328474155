<template>
  <v-data-table
    :items-per-page="10"
    :headers="headers"
    :items="getStationCharges"
    :loading="loading"
  >
    <template v-slot:[`item.seconds_left`]="{ item }">
      <count-down-timer
        v-if="item.seconds_left"
        :charge_time_in_minutes="item.seconds_left"
      ></count-down-timer>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        v-if="item.status == 'CHARGING' || item.status == 'IDLE'"
        v-model="item.actions"
        @click="stopActiveCharge(item)"
        color="red"
        >Stop charge</v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountDownTimer from "./CountDownTimer.vue";
export default {
  components: { CountDownTimer },
  data() {
    return {
      headers: [
        {
          text: "Port number",
          value: "charging_port.charging_port_number",
        },
        {
          text: "Port status",
          value: "status",
        },
        {
          text: "Charge time left",
          value: "seconds_left",
        },
        {
          text: "Customer",
          value: "fullName",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCurrentStation", "getStationCharges"]),
    loading() {
      if (this.getStationCharges.length) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    ...mapActions(["stopCharge", "makeStationPortTableData"]),

    async stopActiveCharge(item) {
      (item.seconds_left = 0),
        (item.fullName = ""),
        (item.status = "-"),
        await this.stopCharge(item.id);
      await this.makeStationPortTableData(this.getCurrentStation);
    },
  },

  async mounted() {
    await this.makeStationPortTableData(this.getCurrentStation);
  },
};
</script>

<style></style>
