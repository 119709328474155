<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center">
        <VuePhoneNumberInput
          class="phone-input"
          v-model="phoneInput"
          :error="error"
          :dark="this.$vuetify.theme.dark"
          @update="onPhoneNumberUpdate"
          @keydown.native.enter="submit"
        />
        <v-btn
          class="mt-2"
          color="primary"
          id="phone-submit-button"
          aria-label="submit-code"
          @click="submit"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    VuePhoneNumberInput,
  },

  data() {
    return {
      phoneInput: "",
      phoneInputObject: {},
      error: false,
    };
  },

  computed: {
    ...mapGetters(["authStatus"]),
  },

  methods: {
    ...mapActions(["promptVerificationCode", "verifyCode"]),
    async submit() {
      if (!this.phoneInputObject.isValid) {
        this.error = true;
        return;
      }
      await this.promptVerificationCode({
        callingCode: "+" + this.phoneInputObject.countryCallingCode,
        phone: this.phoneInputObject.nationalNumber,
      });
      if (this.authStatus == "error") {
        this.error = true;
        return;
      }
      this.$emit("submit", {
        countryCallingCode: this.phoneInputObject.countryCallingCode,
        number: this.phoneInputObject.nationalNumber,
      });
    },

    onPhoneNumberUpdate(payload) {
      this.error = false;
      this.phoneInputObject = payload;
    },
  },
};
</script>
<style scoped>
#phone-submit-button {
  margin: auto;
}
.phone-input {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
