<template>
  <v-container v-if="isAuthenticated">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <h1 class="title">Edit User</h1>
        <v-text-field
          class="text-field-max-width"
          label="First Name"
          v-model="currentUser.first_name"
        ></v-text-field>
        <v-text-field
          class="text-field-max-width"
          label="Last Name"
          v-model="currentUser.last_name"
        ></v-text-field>
        <v-text-field
          class="text-field-max-width"
          label="Email"
          type="email"
          name="email"
          id="email"
          v-model="currentUser.email"
        ></v-text-field>
        <v-text-field
          class="text-field-max-width"
          disabled
          label="Phone"
          v-model="currentUser.phone"
        ></v-text-field>

        <v-btn color="primary" @click="update">Save</v-btn>

        <v-dialog persistent v-model="dialog" max-width="290">
          <v-card>
            <v-card-title class="headline justify-center">
              {{ headlineText }}
            </v-card-title>
            <v-card-text align="center">
              <v-avatar :color="this.iconColor">
                <v-icon dark> {{ this.icon }} </v-icon>
              </v-avatar>
            </v-card-text>

            <v-card-text align="center"> {{ bodyText }} </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="closeDialog">
                {{ buttonText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      headlineText: "",
      bodyText: "",
      buttonText: "",
      dialog: false,
      icon: "",
      iconColor: "",
    };
  },

  computed: mapGetters(["currentUser", "currentError", "isAuthenticated"]),

  methods: {
    ...mapActions(["updateUser", "updateError"]),
    async update() {
      await this.updateUser(this.currentUser);
      if (this.currentError == 500) {
        this.dialog = true;
        this.headlineText = "An error occurred";
        this.bodyText = "The email is allready taken, please use another";
        this.buttonText = "OK";
        this.iconColor = "error";
        this.icon = "mdi-alert-circle";
        this.updateError(null);
      } else {
        this.dialog = true;
        this.headlineText = "Success";
        this.bodyText = "User saved successfully";
        this.buttonText = "Home";
        this.iconColor = "success";
        this.icon = "mdi-check-circle";
      }
    },

    closeDialog() {
      if (this.currentError == 500) {
        this.dialog = false;
        this.updateError(null);
      } else {
        this.dialog = false;
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.text-field-max-width {
  max-width: 70%;
}
</style>
