<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center">
        <h1 class="title">Station overview</h1>
        <station-tabs></station-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StationTabs from "./StationTabs.vue";
export default {
  components: { StationTabs },
};
</script>

<style></style>
