<template>
  <v-card elevation="0" color="mb-8">
    <payout-frequency></payout-frequency>
    <payment-methods></payment-methods>
  </v-card>
</template>

<script>
import PaymentMethods from "./PaymentMethods.vue";
import PayoutFrequency from "./PayoutFrequency.vue";
export default {
  components: { PayoutFrequency, PaymentMethods },
};
</script>

<style></style>
