<template>
  <div>
    <h2 class="my-10" align="center" justify="center">Opening Hours</h2>
    <v-data-table :headers="headers" :items="data">
      <template v-slot:top>
        <v-dialog v-model="dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-row
              ><v-col cols="12">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 px-5 addButtonMargin"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add
                  <v-icon dark right> mdi-calendar-plus </v-icon>
                </v-btn></v-col
              ></v-row
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      :items="daysOfTheWeek"
                      v-model="editedItem.day_of_the_week"
                      label="Day of the week"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <h2>From:</h2>
                    <v-text-field
                      type="time"
                      use-seconds
                      :max="editedItem.to"
                      v-model="editedItem.from"
                      title="From"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <h2>To:</h2>
                    <v-text-field
                      type="time"
                      :min="editedItem.from"
                      v-model="editedItem.to"
                      title="To"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      outlined
      :color="snackbarColor"
      text
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    currentStation: Object,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Day of the week",
          value: "day_of_the_week",
        },
        {
          text: "From",
          value: "from",
        },
        {
          text: "To",
          value: "to",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      data: [],
      editedIndex: -1,
      editedItem: {
        id: null,
        day_of_the_week: "",
        from: null,
        to: null,
      },
      defaultItem: {
        id: null,
        day_of_the_week: "",
        from: null,
        to: null,
      },
      daysOfTheWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      snackbar: false,
      snackbarText: "",
      snackbarColor: "primary",
      snackbarTimeout: -1,
    };
  },
  computed: {
    ...mapGetters(["getOpeningHourError"]),
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions([
      "addOpeningHour",
      "updateOpeningHour",
      "deleteOpeningHour",
      "updateOpeningHourError",
    ]),
    setTableData() {
      this.currentStation.opening_hours.forEach((openingHour) => {
        let fromRaw = openingHour.from.split(":");
        let from = fromRaw.slice(0, -1).join(":");
        let toRaw = openingHour.to.split(":");
        let to = toRaw.slice(0, -1).join(":");
        this.data.push({
          id: openingHour.id,
          day_of_the_week: openingHour.day_of_the_week,
          from: from,
          to: to,
        });
      });
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteOpeningHour({
        id: this.editedItem.id,
      });
      if (this.getOpeningHourError != null) {
        this.snackbar = true;
        this.snackbarText = this.getOpeningHourError;
        this.snackbarColor = "red";
        this.snackbarTimeout = 4000;
        await this.updateOpeningHourError(null);
      }
      await this.updateOpeningHourError(null);
      this.snackbar = true;
      this.snackbarText = "Opening hours deleted succesfully";
      this.snackbarColor = "primary";
      this.snackbarTimeout = 2000;
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateOpeningHour({
          station_id: this.currentStation.id,
          id: this.editedItem.id,
          day_of_the_week: this.editedItem.day_of_the_week,
          from: this.editedItem.from + ":00",
          to: this.editedItem.to + ":00",
        });
        if (this.getOpeningHourError == null) {
          await this.updateOpeningHourError(null);
          this.snackbar = true;
          this.snackbarText = "Opening hours succesfully updated";
          this.snackbarTimeout = 2000;
          this.snackbarColor = "primary";
          Object.assign(this.data[this.editedIndex], this.editedItem);
        } else {
          this.snackbar = true;
          this.snackbarText = this.getOpeningHourError;
          this.snackbarColor = "red";
          this.snackbarTimeout = 4000;
          await this.updateOpeningHourError(null);
        }
      } else {
        const response = await this.addOpeningHour({
          station_id: this.currentStation.id,
          day_of_the_week: this.editedItem.day_of_the_week,
          from: this.editedItem.from + ":00",
          to: this.editedItem.to + ":00",
        });
        if (this.getOpeningHourError == null) {
          this.editedItem.id = response.data.id;
          await this.updateOpeningHourError(null);
          this.snackbar = true;
          this.snackbarText = "Opening hours succesfully added";
          this.snackbarTimeout = 2000;
          this.snackbarColor = "primary";
          this.data.push(this.editedItem);
        } else {
          this.snackbar = true;
          this.snackbarText = this.getOpeningHourError;
          this.snackbarColor = "red";
          this.snackbarTimeout = 4000;
          await this.updateOpeningHourError(null);
        }
      }
      this.close();
    },
  },
  mounted() {
    this.setTableData();
  },
};
</script>

<style scoped>
.addButtonMargin {
  margin-right: 2px;
}
</style>
