<template>
  <v-skeleton-loader
    v-if="loading"
    type="image"
    width="100%"
    height="500px"
    :loading="true"
  ></v-skeleton-loader>
  <v-card elevation="0" v-else>
    <v-list>
      <v-list-item class="center" two-line>
        <v-list-item-title>Total:</v-list-item-title>
        <v-list-item-subtitle
          >{{ getStats.total_earnings }}
          {{ getStats.currency_short_name }}</v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item class="center" two-line>
        <v-list-item-title>Last year:</v-list-item-title>
        <v-list-item-subtitle
          >{{ getStats.earned_last_year }}
          {{ getStats.currency_short_name }}</v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item class="center" two-line>
        <v-list-item-title>Last month:</v-list-item-title>
        <v-list-item-subtitle
          >{{ getStats.earned_last_month }}
          {{ getStats.currency_short_name }}</v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item class="center" two-line>
        <v-list-item-title>last week:</v-list-item-title>
        <v-list-item-subtitle
          >{{ getStats.earned_last_week }}
          {{ getStats.currency_short_name }}</v-list-item-subtitle
        >
      </v-list-item>
      <v-list-item class="center" two-line>
        <v-list-item-title>Not paid out:</v-list-item-title>
        <v-list-item-subtitle
          >{{ getStats.undisbursed_earnings }}
          {{ getStats.currency_short_name }}</v-list-item-subtitle
        >
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["currentPartner", "currentUser", "getStats"]),
  },
  methods: {
    ...mapActions(["fetchStats"]),
    async fetchData() {
      this.loading = true;
      await this.fetchStats(this.currentPartner.id);
      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.theme--dark.v-list {
  background-color: #121212 !important;
}
</style>
