<template>
  <v-tabs color="primary">
    <v-tab class="tab-font-size">Payouts</v-tab>
    <v-tab class="tab-font-size">Settings</v-tab>
    <v-tab-item>
      <payouts-wrapper></payouts-wrapper>
    </v-tab-item>
    <v-tab-item class="mx-3">
      <payout-settings></payout-settings>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";
import PayoutSettings from "./PayoutSettings.vue";
import PayoutsWrapper from "./PayoutsWrapper.vue";

export default {
  components: { PayoutSettings, PayoutsWrapper },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentUser"]),
  },
};
</script>

<style>
.tab-font-size {
  font-size: 1rem !important;
}
</style>
