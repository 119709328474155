/* eslint-disable */

import axios from "axios";

const state = {
  user: {},
  error: null,
};

const getters = {
  currentUser: (state) => state.user,
  currentError: (state) => state.error,
};

const actions = {
  async fetchUser({ commit }) {
    const response = await axios.get("/user");
    commit("setUser", response.data);
  },
  updateUser({ commit }, user) {
    return axios
      .put(`/users/${user.id}`, user)
      .then(function (response) {
        commit("setUser", response.data);
      })
      .catch(function (error) {
        commit("setError", error.response.status);
      });
  },
  // function to make explicit that points are being fetched, might be changed in the future in favor of a local solution
  async updatePoints({ commit }) {
    const response = await axios.get("/user");
    commit("setUser", response.data);
  },
  async updateError({ commit }, payload) {
    commit("setError", payload);
  },

  async initUserEventsChannel({ commit }, userId) {
    this.$userEventsChannel = this.$echo.private("user." + userId + ".events");
  },

  subscribeToUserUpdates({ commit }) {
    this.$userEventsChannel.listen("UserUpdated", (payload) => {
      commit("setUser", payload.user);
    });
  },
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
