<template>
  <v-app-bar height="66" fixed app clipped-right>
    <v-spacer></v-spacer>
    <img
      height="30"
      src="../assets/mPowerStation.png"
      alt="mPowerStation Logo"
      @click="goToHome"
      class="cursor-pointer margin-logo"
    />

    <v-spacer></v-spacer>
    <v-icon class="mr-2">mdi-theme-light-dark</v-icon>
    <v-switch
      v-model="$vuetify.theme.dark"
      @click="toggleDarkMode"
      inset
      class="mt-5"
    >
    </v-switch>
    <v-icon @click="setDrawer(!drawer)" v-if="isAuthenticated">
      mdi-menu
    </v-icon>
    <slot />
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["drawer"]),
    ...mapGetters(["isAuthenticated", "currentUser"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    goToHome() {
      this.$router.push("/");
    },
    toggleDarkMode() {
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffff !important;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background-color: #121212 !important;
}
.margin-logo {
  margin-left: 100px;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.pill {
  background-color: #8eb360;
  color: white !important;
  border: 1px solid #8eb360;
  border-radius: 20px;
  padding: 3px;
  height: 32px;
}
</style>
