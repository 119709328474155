<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center">
        <h1 class="title">Payouts overview</h1>
        <payouts-tabs></payouts-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PayoutsTabs from "./PayoutsTabs.vue";
export default {
  components: { PayoutsTabs },
};
</script>

<style></style>
