<template>
  <div>
    <h2 align="center" justify="center" class="my-4">Minutes Per Point</h2>
    <v-row>
      <v-col cols="12">
        <v-slider
          v-model="currentStation.minutes_per_point"
          class=""
          :max="currentStation.country.max_minutes_per_point"
          :min="currentStation.country.min_minutes_per_point"
          thumb-label="always"
        ></v-slider
      ></v-col>
      <v-col cols="12"
        ><v-btn @click="updateMinutes" class="" color="primary"
          >Save <v-icon right>mdi-content-save</v-icon></v-btn
        ></v-col
      >
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      outlined
      :color="color"
      text
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    currentStation: Object,
  },
  data() {
    return {
      snackbar: false,
      timeout: -1,
      text: "",
      color: "",
    };
  },
  computed: {
    ...mapGetters(["getUpdateMinutesPerPointError"]),
  },
  methods: {
    ...mapActions(["updateStationMinutesPerPoint", "updateMintuesError"]),
    async updateMinutes() {
      await this.updateStationMinutesPerPoint({
        id: this.currentStation.id,
        minutesPerPoint: this.currentStation.minutes_per_point,
      });
      if (this.getUpdateMinutesPerPointError != false) {
        this.snackbar = true;
        this.text =
          "This station has active charging. You cant change charging cost while there is an active charging";
        this.color = "red";
        await this.updateMintuesError(false);
        this.timeout = 4000;
      } else {
        this.snackbar = true;
        this.color = "primary";
        this.text = "Minutes per point updated";
        this.timeout = 2000;
      }
    },
  },
};
</script>

<style>
.inline {
  display: flex;
}
</style>
