import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/user";
import partner from "./modules/partner";
import station from "./modules/station";
import charge from "./modules/charge";
import Echo from "laravel-echo";

const token = localStorage.getItem("token");

window.Pusher = require("pusher-js");
// Vuex.Store.prototype.$echo = new Echo({
//   broadcaster: "pusher",
//   authEndpoint: process.env.VUE_APP_LOCAL
//     ? "http://localhost:8084/broadcasting/auth"
//     : "https://api.mpowerstation.com/broadcasting/auth",
//   auth: {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   },
//   key: process.env.VUE_APP_PUSHER_KEY,
//   wsHost: process.env.VUE_APP_LOCAL ? "localhost" : "api.mpowerstation.com",
//   wsPort: process.env.VUE_APP_LOCAL ? 6001 : 6002,
//   wssPort: process.env.VUE_APP_LOCAL ? 6001 : 6002,
//   forceTLS: process.env.VUE_APP_LOCAL ? false : true,
//   disableStats: true,
//   enabledTransports: ["ws", "wss"],
// });
/* Vuex.Store.prototype.$http = axios;
const token = localStorage.getItem("token");

Vuex.Store.prototype.$http.defaults.headers.common["Authorization"] = token
  ? "Bearer " + token
  : null;
Vuex.Store.prototype.$http.defaults.headers.common["Content-Type"] =
  "application/json";
Vuex.Store.prototype.$http.defaults.headers.common["Accept"] =
  "application/json";
Vuex.Store.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL; */

Vuex.Store.prototype.$echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: 'eu',
  forceTLS: true,
  authEndpoint: 'https://api.mpowerstation.com/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  },
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: null,
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },
  actions: {},
  modules: {
    auth,
    user,
    partner,
    station,
    charge,
  },
});
