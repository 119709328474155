<template>
  <landing-page />
</template>

<script>
import LandingPage from "../components/LandingPage.vue";

export default {
  name: "Home",

  components: {
    LandingPage,
  },
};
</script>
