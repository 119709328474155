import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import UserView from "../views/UserView.vue";
import StationView from "../views/StationView.vue";
import FallbackView from "../views/FallbackView.vue";
import PageNotFound from "../components/PageNotFound.vue";
import UserEdit from "../components/user/UserEdit.vue";
import StationOverview from "../components/station/StationOverview.vue";
import PayoutsOverview from "../components/payouts/PayoutsOverview.vue";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  const loginpath = window.location.pathname;
  if (loginpath == null) {
    store.dispatch("updateOriginalRoute", "/");
  }
  store.dispatch("updateOriginalRoute", loginpath);
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: "/home",
    name: "home",
    alias: "/",
    component: HomeView,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/usernotpartner",
    name: "usernotpartner",
    component: FallbackView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/station",
    name: "station",
    beforeEnter: ifAuthenticated,
    component: StationView,
    children: [
      {
        path: ":humanSerial",
        name: "stationOverveiw",
        component: StationOverview,
      },
    ],
  },
  {
    path: "/payouts",
    name: "payouts",
    beforeEnter: ifAuthenticated,
    component: PayoutsOverview,
  },
  {
    path: "/user",
    component: UserView,

    beforeEnter: ifAuthenticated,
    children: [
      {
        name: "editUser",
        path: "edit",
        component: UserEdit,
      },
    ],
  },

  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
