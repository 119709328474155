/* eslint-disable no-unused-vars */
import axios from "axios";
import Vue from "vue";

const state = {
  allStationCharges: [],
  stationCharges: [],
};

const getters = {
  getAllStationCharges: (state) => state.allStationCharges,
  getStationCharges: (state) => state.stationCharges,
};

const actions = {
  async fetchPortCharge({ commit }, id) {
    const response = await axios.get(
      `/charges/${id}?withChargingPort&withUser`
    );
    return response.data;
  },
  async fetchAllStationCharges(
    { commit },
    { id, page, perPage, orderBy, direction }
  ) {
    const response = await axios.get(
      `/stations/${id}/charges?per_page=${perPage}&page=${page}${
        orderBy.length ? "&order_by=" + orderBy : ""
      }&order_direction=${direction}&withUser`
    );
    commit("setAllStationCharges", response.data);
  },
  async stopCharge({ commit }, payload) {
    const response = await axios.post(`/charges/${payload}/stop`);
  },

  subscribeToStationCharges({ commit, dispatch }) {
    this.$stationEventsChannel.listen("ChargeCreatedOrUpdated", (payload) => {
      commit("setActiveCharge", payload.charge);
    });
  },
  async makeStationPortTableData({ commit, dispatch }, station) {
    let activeCharge = null;
    for (const port of station.charging_ports) {
      if (port.status == "INACTIVE") {
        port.status = "-";
      }
      Vue.set(state.stationCharges, port.charging_port_number, {
        charging_port: {
          charging_port_number: port.charging_port_number,
        },
        status: port.status,
      });
      if (port.currently_reserved_by_charge_id) {
        activeCharge = await dispatch(
          "fetchPortCharge",

          port.currently_reserved_by_charge_id
        );

        commit("setActiveCharge", activeCharge);
      }
    }
  },
};

const mutations = {
  setAllStationCharges: (state, payload) => {
    state.allStationCharges = payload;
  },
  setActiveCharge: (state, activeCharge) => {
    if (activeCharge.status == "CHARGING" || activeCharge.status == "IDLE") {
      activeCharge.fullName =
        activeCharge.user.first_name + " " + activeCharge.user.last_name;
    }
    Vue.set(
      state.stationCharges,
      activeCharge.charging_port.charging_port_number,
      activeCharge
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
