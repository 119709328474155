<template>
  <v-card elevation="0">
    <v-card elevation="0">
      <v-card-title class="card-title">Abnormal battery:</v-card-title>
      <v-row>
        <v-col>
          <p v-if="getCurrentStation.abnormal_battery">
            <v-icon>mdi-battery-unknown</v-icon>
            {{ getCurrentStation.abnormal_battery }}
          </p>
          <p v-else><v-icon>mdi-battery-unknown</v-icon> Battery normal</p>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="card-title">Battery current:</v-card-title>
      <v-row>
        <v-col>
          <p v-if="getCurrentStation.battery_current">
            <v-icon>mdi-lightning-bolt</v-icon>
            {{ getCurrentStation.battery_current }}
          </p>
          <p v-else><v-icon>mdi-lightning-bolt</v-icon> No current</p>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="card-title">Battery tamper:</v-card-title>
      <v-row>
        <v-col>
          <p v-if="getCurrentStation.battery_tamper">
            <v-icon>mdi-battery-alert</v-icon>
            {{ getCurrentStation.battery_tamper }}
          </p>
          <p v-else><v-icon>mdi-battery-alert</v-icon> No Tamper</p>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="card-title">Battery voltage:</v-card-title>
      <v-row>
        <v-col>
          <p v-if="getCurrentStation.battery_voltage">
            <v-icon>mdi-battery-charging-100</v-icon>
            {{ getCurrentStation.battery_voltage }}
          </p>
          <p v-else><v-icon>mdi-battery-charging-100</v-icon> No voltage</p>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="card-title"> Box tamper:</v-card-title>
      <v-row>
        <v-col>
          <p v-if="getCurrentStation.box_tamper">
            <v-icon>mdi-alert</v-icon>
            {{ getCurrentStation.box_tamper }}
          </p>
          <p v-else><v-icon>mdi-alert</v-icon> No tamper</p>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getCurrentStation"]),
  },
};
</script>

<style>
.card-title {
  display: flex;
  justify-content: center;
}
</style>
