<template>
  <v-card elevation="0">
    <charge-history-table
      :currentStation="getCurrentStation"
    ></charge-history-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ChargeHistoryTable from "./ChargeHistoryTable.vue";
export default {
  components: { ChargeHistoryTable },
  computed: {
    ...mapGetters(["getCurrentStation"]),
  },
};
</script>

<style></style>
