<template>
  <v-card elevation="0">
    <station-name-input class="my-6" :currentStation="getCurrentStation" />
    <station-points-per-minute-slider
      class="my-6"
      :currentStation="getCurrentStation"
    />
    <station-frequency class="my-6" :currentStation="getCurrentStation" />
    <station-opening-hours-input
      class="my-6"
      :currentStation="getCurrentStation"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import StationFrequency from "./StationFrequency.vue";
import StationNameInput from "./StationNameInput.vue";
import StationOpeningHoursInput from "./StationOpeningHoursInput.vue";
import StationPointsPerMinuteSlider from "./StationPointsPerMinuteSlider.vue";
export default {
  components: {
    StationNameInput,
    StationPointsPerMinuteSlider,
    StationOpeningHoursInput,
    StationFrequency,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getCurrentStation"]),
  },
};
</script>

<style></style>
