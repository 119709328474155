/* eslint-disable no-unused-vars */

import axios from "axios";

const state = {
  station: {},
  error: false,
  updateNameError: false,
  updateMinutesPerPointError: false,
  openingHourError: false,
  updateFrequencyError: false,
};

const getters = {
  getCurrentStation: (state) => state.station,
  getUpdateNameError: (state) => state.updateNameError,
  getUpdateMinutesPerPointError: (state) => state.updateMinutesPerPointError,
  getOpeningHourError: (state) => state.openingHourError,
  getUpdateFrequencyError: (state) => state.updateFrequencyError,
};

const actions = {
  async fetchCurrentStationByHumanSerial({ commit }, serial) {
    try {
      if (state.station.human_serial !== serial) {
        commit("setStation", {});
      }
      const response = await axios.get(
        `/stations?human_serial=${serial}&withChargingPorts&withOpeningHours&withCountry`
      );
      if (response.data.length < 1) {
        commit("setError", true);
      } else {
        commit("setStation", response.data[0]);
      }
    } catch (error) {
      if (error) {
        commit("setError", true);
      }
    }
  },
  async updateStationName({ commit }, { id, name }) {
    try {
      const response = await axios.put(`/stations/${id}`, {
        name: name,
      });
      commit("setUpdateNameError", false);
    } catch (error) {
      commit("setUpdateNameError", true);
    }
  },
  async updateNameError({ commit }, payload) {
    commit("setUpdateNameError", payload);
  },

  async updateStationMinutesPerPoint({ commit }, { id, minutesPerPoint }) {
    try {
      const response = await axios.put(`/stations/${id}`, {
        minutes_per_point: minutesPerPoint,
      });
    } catch (error) {
      commit("setUpdateMinutesPerPointError", true);
    }
  },
  async updateMintuesError({ commit }, payload) {
    commit("setUpdateMinutesPerPointError", payload);
  },
  async updateStationFrequency({ commit }, { id, frequency }) {
    try {
      const response = await axios.put(`/stations/${id}`, {
        frequency: frequency,
      });
      console.log(response);
    } catch (error) {
      console.log("error true");
      commit("setUpdateFrequencyError", true);
    }
  },
  async updateFrequencyError({ commit }, payload) {
    commit("setUpdateFrequencyError", payload);
  },
  async addOpeningHour({ commit }, { station_id, day_of_the_week, from, to }) {
    try {
      const response = await axios.post(`/openinghours`, {
        station_id: station_id,
        day_of_the_week: day_of_the_week,
        from: from,
        to: to,
      });
      return response;
    } catch (error) {
      commit("setOpeningHourError", error.response.data.message);
    }
  },
  async updateOpeningHour(
    { commit },
    { station_id, id, day_of_the_week, from, to }
  ) {
    try {
      const response = await axios.put(`/openinghours/${id}`, {
        station_id: station_id,
        day_of_the_week: day_of_the_week,
        from: from,
        to: to,
      });
    } catch (error) {
      commit("setOpeningHourError", error.response.data.message);
    }
  },
  async deleteOpeningHour({ commit }, { id }) {
    try {
      const response = await axios.delete(`/openinghours/${id}`, {});
    } catch (error) {
      commit("setOpeningHourError", error.response.data.message);
    }
  },
  async updateOpeningHourError({ commit }, payload) {
    commit("setOpeningHourError", payload);
  },

  async initStationEventsChannel({ commit }, stationId) {
    this.$stationEventsChannel = this.$echo.private(
      "station." + stationId + ".events"
    );
  },

  subscribeToStationUpdates({ commit }) {
    this.$stationEventsChannel.listen("StationUpdated", (payload) => {
      commit("setStation", payload.station);
    });
  },

  leaveStationEventsChannel({ commit }, stationId) {
    this.$echo.leave("station." + stationId);
  },
};

const mutations = {
  setStation: (state, station) => (state.station = station),
  setError: (state, error) => (state.error = error),
  setUpdateNameError: (state, updateNameError) =>
    (state.updateNameError = updateNameError),
  setUpdateMinutesPerPointError: (state, payload) =>
    (state.updateMinutesPerPointError = payload),
  setOpeningHourError: (state, payload) => (state.openingHourError = payload),
  setUpdateFrequencyError: (state, payload) =>
    (state.updateFrequencyError = payload),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
