<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="../assets/mPowerStation.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
