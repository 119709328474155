<template>
  <user-not-partner></user-not-partner>
</template>

<script>
import UserNotPartner from "../components/login/UserNotPartner.vue";
export default {
  components: { UserNotPartner },
};
</script>

<style></style>
