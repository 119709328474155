<template>
  <div>
    <v-navigation-drawer
      v-if="currentUser"
      v-model="drawer"
      :expand-on-hover="expandOnHover"
      width="300"
      app
      temporary
      clipped
      right
    >
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-avatar color="mr-8">
            <v-icon color="primary" large> mdi-account-circle </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-wrap"
              >{{ currentUser.first_name }} {{ currentUser.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ currentUser.phone }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-btn icon to="/user/edit"
            ><v-icon>mdi-pencil-outline</v-icon></v-btn
          >
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav subheader>
        <v-subheader>Stations</v-subheader>
        <v-list-item
          :key="index"
          v-for="(station, index) in currentPartner.stations"
          @click="navigateToStation(station.human_serial)"
        >
          <v-list-item-icon> <v-icon>mdi-ev-station</v-icon></v-list-item-icon>
          <v-list-item-title>{{ station.human_serial }}</v-list-item-title>
        </v-list-item>
        <v-divider> </v-divider>
        <v-subheader>Payouts</v-subheader>
        <v-list-item to="/payouts">
          <v-list-item-icon><v-icon>mdi-currency-usd</v-icon></v-list-item-icon>
          <v-list-item-title>Payouts</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="primary" @click="logoutUser"> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "currentPartner"]),

    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },

  methods: {
    ...mapActions(["logout"]),

    navigateToStation(humanSerial) {
      this.$router.push(`/station/${humanSerial}`);
    },
    logoutUser() {
      this.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.breakWord {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
}
</style>
