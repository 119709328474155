<template>
  <v-app>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="10"
      color="primary"
      class="spinner__center"
      indeterminate
    ></v-progress-circular>
    <div v-else>
      <the-side-drawer v-if="isAuthenticated"></the-side-drawer>
      <the-top-bar></the-top-bar>
      <v-main>
        <v-container class="container-height" fluid>
          <transition name="slide" mode="out-in">
            <router-view />
          </transition>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TheSideDrawer from "./components/TheSideDrawer.vue";
import TheTopBar from "./components/TheTopBar.vue";

export default {
  components: { TheTopBar, TheSideDrawer },
  name: "App",
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUser"]),
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "fetchPartner",

      "subscribeToUserUpdates",
      "initUserEventsChannel",
    ]),
  },
  async mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else {
      let matched = window.matchMedia("(prefers-color-scheme: dark)").matches;

      if (matched) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }

    await this.fetchUser();
    if (this.currentUser.id) {
      this.initUserEventsChannel(this.currentUser.id).then(() => {
        this.subscribeToUserUpdates();
      });
    }
    await this.fetchPartner(this.currentUser.partner_id);
  },
};
</script>
<style>
/* light theme settings */
.theme--light.v-icon {
  color: black;
}
.theme--light.v-btn.v-btn--icon {
  color: black;
}
/* dark theme settings */
.theme--dark.v-data-table {
  background-color: #121212 !important;
  color: #ffffff;
}
.theme--dark.v-stepper {
  background: #121212 !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #121212 !important ;
}
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: white !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: thin solid white;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}
.theme--dark.v-tabs-items {
  background-color: #121212 !important;
}
.theme--dark.v-card {
  background-color: #121212 !important;
  color: #ffffff;
}
.spinner__center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.container-height {
  height: 100%;
  padding: 0px;
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}
</style>
