<template>
  <div>
    <h2>Charge history</h2>
    <v-data-table
      :page="page"
      :pageCount="numberOfPages"
      :headers="headers"
      :items="data"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="mt-6"
    ></v-data-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    currentStation: Object,
  },
  data() {
    return {
      headers: [
        {
          text: "Date",
          value: "created_at",
        },
        {
          text: "Minutes Charged",
          value: "minutes",
          sortable: false,
        },
        {
          text: "Earned",
          value: "actual_cost",
          sortable: false,
        },
      ],
      data: [],
      loading: true,
      total: 0,
      options: {},
      numberOfPages: 0,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(["getAllStationCharges"]),
  },
  methods: {
    ...mapActions(["fetchAllStationCharges"]),
    async readDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      await this.fetchAllStationCharges({
        id: this.currentStation.id,
        page: page,
        perPage: itemsPerPage,
        orderBy: sortBy,
        direction: sortDesc == "true" ? "DESC" : "ASC",
      });
      let rawData = this.getAllStationCharges.data;
      this.data = rawData.map(this.formatData);
      this.total = this.getAllStationCharges.meta.total;
      this.numberOfPages = this.getAllStationCharges.meta.last_page;
      this.loading = false;
    },

    format(date) {
      return dayjs(date).format("D. MMM YYYY");
    },
    formatMinutes(time) {
      let totalSeconds = time * 60;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (hours < 10) {
        hours = `0${hours}`;
      }
      return `${hours}:${minutes}:${seconds}`;
    },
    formatData(data) {
      return {
        created_at: this.format(data.created_at),
        minutes: this.formatMinutes(data.minutes),
        actual_cost:
          data.this_partner_earned.amount !== null
            ? data.this_partner_earned.amount +
              " " +
              data.this_partner_earned.currency
            : "",
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.readDataFromApi();
      },
    },
    deep: true,
  },
};
</script>

<style></style>
