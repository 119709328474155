<template>
  <div v-if="timeLeft > 1000">{{ formattedTimeLeft }}</div>
  <div v-else>00:00:00</div>
</template>

<script>
export default {
  props: {
    charge_time_in_minutes: { Number, String },
  },
  data() {
    return {
      startTime: this.charge_time_in_minutes * 1000,
      timerInterval: null,
    };
  },
  computed: {
    timeLeft() {
      return this.startTime - 1000;
    },

    formattedTimeLeft() {
      let timeLeft = this.timeLeft;
      let totalSeconds = timeLeft / 1000;
      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = Math.floor(totalSeconds % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (hours < 10) {
        hours = `0${hours}`;
      }
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  watch: {
    timeLeft(newValue) {
      if (newValue == 120000) {
        this.$emit("syncTimer");
      }
      if (newValue <= 1000) {
        this.onTimesUp();
      }
    },
  },

  mounted() {
    if (this.timeLeft < 1000) {
      return;
    } else {
      this.startTimer();
    }
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.startTime -= 1000), 1000);
    },
  },
};
</script>

<style></style>
