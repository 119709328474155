<template>
  <div>
    <h2 class="my-4" align="center" justify="center">Payout method</h2>
    <v-form v-if="!loading">
      <v-row
        ><v-col cols="12">
          <v-select
            label="Method"
            :items="items"
            item-text="name"
            v-model="selected"
            item-value="id"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-btn @click="save" class="" color="primary"
            >Save
            <v-icon right> mdi-content-save </v-icon>
          </v-btn></v-col
        ></v-row
      >
    </v-form>
    <v-skeleton-loader
      v-if="loading"
      type="image"
      width="100%"
      height="153.94px"
      :loading="true"
    ></v-skeleton-loader>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      outlined
      :color="color"
      text
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      snackbar: false,
      timeout: -1,
      text: "",
      color: "",
      selected: { name: "", id: "" },
      items: [],
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters([
      "getDisbursementMethods",
      "currentPartner",
      "currentUser",
      "getPartnerError",
    ]),
  },
  methods: {
    ...mapActions(["fetchDisbursementMethods", "updatePartner"]),
    async makeSelectData() {
      this.loading = true;
      await this.fetchDisbursementMethods();
      this.getDisbursementMethods.forEach((item) => {
        this.items.push({
          name: item.name,
          id: item.id,
        });
      });
      if (this.currentPartner.disbursement_method_id != null) {
        this.selected = {
          name: this.getDisbursementMethods[
            this.currentPartner.disbursement_method_id - 1
          ].name,
          id: this.getDisbursementMethods[
            this.currentPartner.disbursement_method_id - 1
          ].id,
        };
      }
      this.loading = false;
    },
    async save() {
      this.loading = true;
      this.currentPartner.disbursement_method_id = this.selected.id;
      try {
        await this.updatePartner({
          partner_id: this.currentPartner.id,
          body: { disbursement_method_id: this.selected.id },
        });

        this.loading = false;
        this.snackbar = true;
        (this.text = "Payment method updated"), (this.timeout = 2000);
        this.color = "primary";
      } catch (error) {
        this.loading = false;
        this.snackbar = true;
        (this.text = "An error occurred"), (this.timeout = 4000);
        this.color = "red";
      }
    },
  },
  mounted() {
    this.makeSelectData();
  },
};
</script>

<style>
.inline {
  display: flex;
}
</style>
