<template>
  <div>
    <h2 class="my-4" align="center" justify="center">Station Name</h2>
    <v-form v-model="isFormValid">
      <v-row
        ><v-col cols="12">
          <v-text-field
            class="pt-0 mt-0"
            v-model="currentStation.name"
            label="Station name"
            minlength="5"
            counter
            :rules="[rules.counter]"
          ></v-text-field
        ></v-col>
        <v-col cols="12">
          <v-btn
            :disabled="!isFormValid"
            @click="setNewStationName"
            class=""
            color="primary"
            >Save
            <v-icon right> mdi-content-save </v-icon>
          </v-btn></v-col
        ></v-row
      >
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      outlined
      :color="color"
      text
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    currentStation: Object,
  },
  data() {
    return {
      rules: {
        counter: (value) => value.length >= 5 || "Minium 5 characters",
      },
      isFormValid: false,
      snackbar: false,
      timeout: -1,
      text: "",
      color: "",
    };
  },
  computed: {
    ...mapGetters(["getUpdateNameError"]),
  },
  methods: {
    ...mapActions(["updateStationName", "updateNameError"]),
    async setNewStationName() {
      await this.updateStationName({
        id: this.currentStation.id,
        name: this.currentStation.name,
      });
      if (this.getUpdateNameError) {
        this.snackbar = true;
        this.text = "An error has occurred please try again";
        this.timeout = 4000;
        this.color = "red";
        await this.updateNameError(false);
      } else {
        this.snackbar = true;
        this.text = "Station name saved successfully";
        this.timeout = 2000;
        this.color = "primary";
      }
    },
  },
};
</script>

<style>
.inline {
  display: flex;
}
</style>
