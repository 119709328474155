<template>
  <v-data-table
    :page="page"
    :pageCount="numberOfPages"
    :headers="headers"
    :items="data"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': this.rowsPerPage,
    }"
  ></v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      headers: [
        {
          text: "Date",
          value: "created_at",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Method",
          value: "method",
          sortable: false,
        },
      ],
      data: [],
      loading: true,
      total: 0,
      options: {},
      numberOfPages: 0,
      page: 1,
      rowsPerPage: [],
    };
  },
  computed: {
    ...mapGetters(["getDisbursmentData", "currentPartner", "currentUser"]),
  },
  methods: {
    ...mapActions(["fetchDisbursmentData"]),
    async readDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      await this.fetchDisbursmentData({
        id: this.currentPartner.id,
        page: page,
        perPage: itemsPerPage,
        orderBy: sortBy,
        direction: sortDesc == "true" ? "DESC" : "ASC",
      });
      let rawData = this.getDisbursmentData.data;
      this.data = rawData.map(this.formatData);
      this.total = this.getDisbursmentData.meta.total;
      this.numberOfPages = this.getDisbursmentData.meta.last_page;
      this.rowsPerPage = [5, 10, 20, 50, this.total].sort(function (a, b) {
        return a - b;
      });

      this.loading = false;
    },

    format(date) {
      return dayjs(date).format("D. MMM YYYY");
    },

    formatData(data) {
      if (data.disbursement_method == null) {
        return {
          created_at: this.format(data.created_at),
          amount: data.amount + " " + data.currency.short_name,
          method: "None",
        };
      } else if (data.currency == null) {
        return {
          created_at: this.format(data.created_at),
          amount: data.amount + " " + "No Currency",
          method: data.disbursement_method.name,
        };
      } else if (data.disbursement_method == null && data.currency == null) {
        return {
          created_at: this.format(data.created_at),
          amount: data.amount + " " + "No Currency",
          method: "None",
        };
      } else {
        return {
          created_at: this.format(data.created_at),
          amount: data.amount + " " + data.currency.short_name,
          method: data.disbursement_method.name,
        };
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.readDataFromApi();
      },
    },
    deep: true,
  },
};
</script>

<style>
.center {
  display: block !important;
}
</style>
